
import Axios, { AxiosInstance } from 'axios';
import storage from '../common/Storage';
import Cookie from 'js-cookie';
import Auth from '@/sdk/common/Auth';
import Vue from 'vue';
import { ApiVersion, ApiServer } from '../common/SysConst';
class WSAPI {
  debug: boolean = true;
  apiHost: string = '';
  apiPath: string = this.apiHost + '/' + ApiVersion;
  controller: Object[];
  qs = require('qs');
  get instance () :AxiosInstance {
    let ins = Axios.create({
      //  baseURL: this.apiPath,
      timeout: 10000,
      headers: { 'Authorization': 'bearer ' + Cookie.get('access_token') }// storage.get('access_token')
    });
    ins.interceptors.request.use(config => {
      let contentType = config.headers.contentType;
      config.method === 'post'
        ? config.data = !contentType || contentType === 'application/x-www-form-urlencoded' ? this.qs.stringify({ ...config.data }) : config.data
        : config.params = { ...config.params };
      if (!contentType) config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      if (storage.get('timeout') < new Date().getTime()) throw new Error('timeout'); // 如已超時則在請求前打斷
      return config;
    }, error => { // 请求错误处理
      console.log(error);
    }
    );
    ins.interceptors.response.use(
      response => {
        storage.set(
          'timeout',
          storage.get('expires') * 1000 + new Date().getTime()
        );
        return Promise.resolve(response);
      },
      error => {
        Vue.prototype.$HiddenLayer();
        if (!error.response && error instanceof Error) {
          Vue.prototype.$HiddenLayer();
          // Vue.prototype.$SingtonConfirm('message', '由于您长时间未操作需要刷新页面或重新登陆。', () => {
          if (storage.get('isLogin') === 1) { storage.set('isLogin', 0); Cookie.remove('access_token'); Auth.GetToken().then(() => { Vue.prototype.$Login(location.reload.bind(location)); }); } else { storage.set('isLogin', 0); location.reload(); }
          // });
          return Promise.reject(error);
        };
        let status = error.response.status;
        if (status === 403) {
          storage.set('isLogin', 0);
          location.reload();
          // Vue.prototype.$SingtonConfirm('message', '权限不足或登陆超时！是否刷新页面？',
          //   () => { location.reload(); });
        } else if (status === 401) Vue.prototype.$Confirm('message', '401：请先登录！');
        else if (status === 400) Vue.prototype.$SingtonConfirm('message', '400：系統異常！');
        else if (status === 404) Vue.prototype.$Confirm('message', '404：您访问的链接不存在！');
        else if (status === 500) Vue.prototype.$Confirm('message', '500：服务器正在升级中！');
        else if (status === 502) Vue.prototype.$Confirm('message', '502：bad gataway!');
        else if (status === 503) Vue.prototype.$Confirm('message', '503：服务器正忙，请稍后访问！');
        // return Promise.reject(error);
      }
    );
    return ins;
  }
  constructor () {
    this.apiHost = this.getPMHost();
    this.controller = Object[2];
  }
  log (arg1: any, arg2?: any) {
    if (this.debug) {
      console.log(arg1);
      if (arg2 !== undefined) {
        console.log(arg2);
      }
    }
  }

  getPMHost = function () {
    return ApiServer;
  };
}

export { WSAPI };
